import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";
import { BsInfoCircleFill } from "react-icons/bs";
import format from "date-fns/format";
import { setHours, setMinutes } from "date-fns";
import Parse from "html-react-parser";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import http from "../../Services/Interceptor";
import * as url from "../../Constants/Url";
import HandleLocale from "../../utils/HandleLocale";
import ErrorContainer from "../../Layout/ErrorContainer";
import VisioInfo from "./VisioInfo";
import {UserStateInterface} from "../../interfaces/UserStateInterface";
import { RecipientsInterface } from "../../interfaces/MessagesInterface";
import HeaderContainer from "../../Layout/HeaderContainer";
interface VisioConstraintsInterface {
	validity?: {
		type: number;
		values: { day: number; ranges: [number[]] }[];
	};
	description?: string;
	home_id: number;
}

interface VisioRequestProps {
	userState: UserStateInterface;
}

const VisioRequest = ({ userState }: VisioRequestProps) => {
	const { t } = useTranslation("common");
	const seniorSelectedId = userState.seniorSelectedId;
	const [visioAlreadyPlanned, setVisioAlreadPlanned] = useState(false);
	const [httpResponse, setHttpResponse] = useState<{ error: boolean } | null>(null);
	const [recipients, setRecipients] = useState<RecipientsInterface[]>([]);
	const [recipientError, setRecipientError] = useState(false);
	const [myRecipient, setMyRecipient] = useState<string | null>(null);
	const userLocale = HandleLocale(userState.locale);
	const date = new Date();
	const [startDate, setStartDate] = useState(date);
	const [startTime, setStartTime] = useState(date);
	const [visioConstraints, setVisioConstraints] = useState<VisioConstraintsInterface | null>(null);
	const [moreInfo, setMoreInfo] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (seniorSelectedId) {
			setLoading(true);
			// get visio Description and constraints from home
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/home/visio/validity`)
				.then(res => setVisioConstraints(res.data.home_visio_validity))
				.catch(() => setVisioConstraints(null));
			// get recipients list
			http
				.get(`${url.SENIORS}/${seniorSelectedId}/visio/recipients`)
				.then(res => {
					const recipients: RecipientsInterface[] = res.data.user_recipients;
					const fullDesignation = recipients.map(recipient => {
						return {
							...recipient,
							name: recipient.designation
								? `${recipient.first_name} ${recipient.last_name}, ${recipient.designation}`
								: `${recipient.first_name} ${recipient.last_name}`
						};
					});
					setRecipients(fullDesignation);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
					setRecipients([]);
				});
		}
	}, [seniorSelectedId]);

	const visioAppointment = (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!myRecipient) {
			setRecipientError(true);
		} else {
			setRecipientError(false);
			setVisioAlreadPlanned(false);
			setHttpResponse(null);
			const visioDate = format(startDate, "yyyy-MM-dd");
			const visioTime = format(startTime, "HH:mm:ss");
			const visioAppointment = visioDate + "T" + visioTime;

			const data = {
				senior_id: seniorSelectedId,
				user_id: parseInt(myRecipient),
				visio_datetime: visioAppointment
			};

			http
				.post(`${url.HOMES}/visio/new`, data)
				.then(() => {
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth"
					});
					setHttpResponse({ error: false });
				})
				.catch(err => {
					if (err.request.status === 400) {
						setVisioAlreadPlanned(true);
						setHttpResponse({ error: true });
					} else {
						setHttpResponse({ error: true });
					}
				});
		}
	};

	const handleISODays = function (day: number) {
		switch (day) {
			case 7:
				return 0;
			default:
				return day;
		}
	};

	const constraints = visioConstraints ? (visioConstraints.validity ? visioConstraints.validity.values : null) : null;
	const visioDaysAuthorized = constraints
		? constraints.map(elt => {
				return {
					...elt,
					day: handleISODays(elt.day)
				};
		  })
		: null;

	const handleFilteredDays = (date: Date) => {
		if (visioDaysAuthorized && visioDaysAuthorized.length > 0) {
			const visioDays = visioDaysAuthorized.map(elt => elt.day);
			const day = date.getDay();
			return visioDays.includes(day);
		} else {
			const visioDays = [0, 1, 2, 3, 4, 5, 6];
			const day = date.getDay();
			return visioDays.includes(day);
		}
	};

	const handleDatePicker = (visioDaysAuthorized: { day: number; ranges: [number[]] }[], startDate: Date) => {
		const day = startDate.getDay();
		const selectedDay = visioDaysAuthorized.find((elt: any) => elt.day === day);
		const timeConstraints = selectedDay ? selectedDay.ranges : null;
		const start = timeConstraints ? timeConstraints[0] : [0, 0];
		const end = timeConstraints ? timeConstraints[timeConstraints.length - 1] : [23, 45];
		return (
			<DatePicker
				required
				selected={startTime}
				onChange={(dateTime: Date) => setStartTime(dateTime)}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={15}
				timeCaption={t("calendar.__hour__")}
				dateFormat="p"
				locale={userLocale}
				minTime={setHours(setMinutes(new Date(), start[1]), start[0])}
				maxTime={setHours(setMinutes(new Date(), end[3]), end[2])}
			/>
		);
	};

	const button = () => {
		return <BsInfoCircleFill className="right more-info-icon" onClick={() => setMoreInfo(prevState => !prevState)} />;
	};

	return (
		<>
			<HeaderContainer title={t("mailbox.__askForVisio__")} button={button} />

			{moreInfo ? (
				<VisioInfo setMoreInfo={setMoreInfo} />
			) : (
				<>
					<ErrorContainer
						alert={
							visioAlreadyPlanned
								? t("calendar.__visioAlreadyExist__")
								: `${t("common.__errorOccured__")} ${t("common.__tryAgain__")}`
						}
						success={t("calendar.__visioSuccess__")}
						httpResponse={httpResponse}
						setHttpResponse={setHttpResponse}
					/>

					{httpResponse && !httpResponse.error ? (
						<>
							<div className="space"></div>
							<div className="card">
								<p>
									<b>{t("common.__toKnow__")} :</b>
								</p>
								<ul className="visio-rules">
									<li>{t("mailbox.__visioModerationMsg__")}</li>
									<li>{t("mailbox.__visioHowToStart__")}</li>
								</ul>
							</div>
						</>
					) : (
						<>
							{visioConstraints && visioConstraints.description ? (
								<div className="colored-background" style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}>
									<div className="tinymce-content">{Parse(visioConstraints.description)}</div>
								</div>
							) : null}

							<form onSubmit={visioAppointment}>
								<Row>
									<Col xs={2} sm={2} md={1} lg={1}>
										<p className="step-number">1</p>
									</Col>
									<Col xs={10} sm={10} md={11} lg={11}>
										{loading ? (
											<br />
										) : recipients.length > 0 ? (
											<>
												<p>
													<strong>{t("calendar.__recipientSelection__")} :</strong>
												</p>

												{recipientError ? <p className="error">{t("mailbox.__pleaseSelectRecipient__")}</p> : null}

												<select name="recipient" onChange={e => setMyRecipient(e.target.value)}>
													<option>{t("mailbox.__selectRecipient__")}</option>
													{recipients.map(recipient => (
														<option key={recipient.id} value={recipient.id}>
															{recipient.name}
														</option>
													))}
												</select>
												<div className="space"></div>
											</>
										) : (
											<p className="alert">{t("mailbox.__noReferentRegistred__")}</p>
										)}
									</Col>

									<Col xs={2} sm={2} md={1} lg={1}>
										<p className="step-number">2</p>
									</Col>
									<Col xs={10} sm={10} md={11} lg={11}>
										<p>
											<strong>{t("mailbox.__selectDayTime__")} :</strong>
										</p>
										<p className="meta mb-5">
											{t("user.__foreignUser__")} <Link to="/settings/myaccount">{t("user.__selectTimezone__")}</Link>
										</p>
									</Col>
									<Col xs={2} sm={2} md={1} lg={1}></Col>
									<Col xs={5} sm={5} md={5} lg={5}>
										<DatePicker
											required
											selected={startDate}
											onChange={(date: Date) => setStartDate(date)}
											locale={userLocale}
											dateFormat="PPP"
											minDate={new Date()}
											filterDate={handleFilteredDays}
										/>
									</Col>
									<Col xs={5} sm={5} md={6} lg={6}>
										{visioDaysAuthorized && visioDaysAuthorized.length > 0 ? (
											handleDatePicker(visioDaysAuthorized, startDate)
										) : (
											<DatePicker
												required
												selected={startTime}
												onChange={(dateTime: Date) => setStartTime(dateTime)}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={15}
												timeCaption={t("calendar.__hour__")}
												dateFormat="p"
												locale={userLocale}
											/>
										)}
									</Col>
									<Col xs={2} sm={2} md={1} lg={1}></Col>
									<Col xs={10} sm={10} md={11} lg={11}>
										<div className="mt-2">
											<button type="submit" className="dark-button">
												{t("calendar.__sendRequest__")}
											</button>
										</div>
									</Col>
								</Row>
							</form>
						</>
					)}
				</>
			)}
		</>
	);
};
export default VisioRequest;

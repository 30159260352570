import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Row, Col } from "react-grid-system";
import { BsFillMicFill, BsPlus } from "react-icons/bs";
import * as url from "../../../Constants/Url";
import MyInputText from "../../../utils/form/MyInputText";
import MyInputTextarea from "../../../utils/form/MyInputTextarea";
import ErrorContainer from "../../../Layout/ErrorContainer";
import useUploadPicts from "../../../hooks/useUploadPicts";
import VoiceRecorder from "./VoiceRecorder";
import VoiceRecorderForAndroid from "./VoiceRecorderForAndroid";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import http from "../../../Services/Interceptor";
import {UserStateInterface} from "../../../interfaces/UserStateInterface";

interface FileInterface {
	name: string;
	size: number;
	preview: string;
	lastModified: number;
	lastModifiedDate: Date;
	type: string;
}

const SendMessage = ({ userState }: { userState: UserStateInterface }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const queryClient = useQueryClient();
	const { handleSubmit } = useFormContext();
	const { isAndroid } = useDeviceDetect();
	const seniorSelectedId = userState.seniorSelectedId;
	const { displayDropzoneUpload, pictsToUpload } = useUploadPicts();
	const [httpResponse, setHttpResponse] = useState<{ error: boolean } | null>(null);
	const [addAudio, setAddAudio] = useState(false);
	const [audioBlob, setAudioBlob] = useState<FileInterface[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [indexes, setIndexes] = useState<number[]>([]);
	const [counter, setCounter] = useState(0);

	const addAudioFile = () => {
		setIndexes(prevIndexes => [...prevIndexes, counter]);
		setCounter(prevCounter => prevCounter + 1);
	};

	const removeAudioFile = (blob: FileInterface, index: number) => {
		const audioFiles = audioBlob.filter(file => file.name !== blob.name);
		setAudioBlob(audioFiles);
		setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
		setCounter(prevCounter => prevCounter - 1);
	};

	useEffect(() => {
		if (addAudio) {
			setIndexes([0]);
			setCounter(1);
		}
	}, [addAudio]);

	const onSubmit = async (data: any) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
		setHttpResponse(null);
		setIsLoading(true);
		const formData: any = new FormData();
		const medias = pictsToUpload.concat(audioBlob);
		for (let i = 0; i < medias.length; i++) {
			formData.append(`file${[i + 1]}`, medias[i]);
		}
		formData.append("subject", data.subject);
		formData.append("message", data.message);
		await mutate(formData);
	};

	const { mutate } = useMutation(data => http.post(`${url.SENIORS}/${seniorSelectedId}/messages/new`, data), {
		onSettled: () => {
			queryClient.invalidateQueries("senior_messages");
		},
		onSuccess: () => {
			queryClient.invalidateQueries("senior_messages");
			setIsLoading(false);
			history.push("/profile/1");
		},
		onError: () => {
			setIsLoading(false);
			setHttpResponse({ error: true });
		}
	});

	return isLoading ? (
		<>
			<div className="loader" role="status"></div>
			<span>
				<b>{t("link.__pleaseWait__")}</b>
			</span>
		</>
	) : (
		<form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
			<div className="tips center">{t("link.__msgTitle__")}</div>
			<ErrorContainer
				alert={`${t("link.__msgSentError__")} ${t("common.__tryAgain__")}`}
				success={t("link.__msgSent__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>
			<MyInputText label={t("link.__msgObject__")} name="subject" behavior={{ required: true }} />
			<MyInputTextarea label={t("common.__message__")} name="message" behavior={{ required: true }} rows="6" />
			<Row>
				<Col xs={12} sm={12} md={6} lg={6}>
					{displayDropzoneUpload()}
				</Col>
				<Col xs={12} sm={12} md={6} lg={6}>
					{addAudio ? (
						<>
							{isAndroid
								? indexes.map(index => (
										<VoiceRecorderForAndroid
											key={index}
											audioBlob={audioBlob}
											setAudioBlob={setAudioBlob}
											index={index}
											addAudioFile={addAudioFile}
											removeAudioFile={removeAudioFile}
										/>
									))
								: indexes.map(index => (
										<VoiceRecorder
											key={index}
											audioBlob={audioBlob}
											setAudioBlob={setAudioBlob}
											index={index}
											addAudioFile={addAudioFile}
											removeAudioFile={removeAudioFile}
										/>
									))}
							<button onClick={() => addAudioFile()} className="pink-button" type="button">
								<BsPlus style={{ verticalAlign: "middle", height: "1.2rem", width: "auto" }} /> {t("link.__addAudioMessage__")}
							</button>
						</>
					) : (
						<div className="upload-zone center" onClick={() => setAddAudio(prevState => !prevState)} role="button">
							<BsFillMicFill color="#4a4e64" className="dropfile-icon mt-2" />
							<p className="mb-5">{t("link.__addAudio__")}</p>
						</div>
					)}
				</Col>
			</Row>

			<div>
				<button type="button" className="light-button" onClick={() => history.goBack()}>
					{t("common.__cancel__")}
				</button>

				<button type="submit" className="dark-button">
					{t("common.__send__")}
				</button>
			</div>
			<div className="space"></div>
		</form>
	);
};

export default SendMessage;
